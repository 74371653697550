import { render, staticRenderFns } from "./orderRecovery-edit.vue?vue&type=template&id=018a2b26&scoped=true&"
import script from "./orderRecovery-edit.vue?vue&type=script&lang=js&"
export * from "./orderRecovery-edit.vue?vue&type=script&lang=js&"
import style0 from "./orderRecovery-edit.vue?vue&type=style&index=0&id=018a2b26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018a2b26",
  null
  
)

export default component.exports