<!-- 订单收回弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单收回':'订单收回'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">

      <el-row :gutter="15">
        <div class="xuanxianglist">
          <div v-for="(item,index) in list" :key="index" class="kuai">
            <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
              {{item.name}}
            </div>
          </div>
        </div>

        <div style="margin-top: 20px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入收回原因"
              v-model="form.textarea2">
          </el-input>

        </div>

      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //数据
      list:[
        {
          name:'距离救援地太远'
        },
        {
          name:'议价太高'
        },
        {
          name:'服务覆盖不到'
        },
        {
          name:'没有师傅'
        },
        {
          name:'合作价格不做'
        },
        {
          name:'拒单'
        },
      ],
      isShow:0,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{
  text-align: center;
  .kuai{
    margin: 10px;
    width: 45%;
    display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .xuanxianglistactive:hover{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
  .xuanxianglistactive1{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
}
</style>
